import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
const secureStorageService = new SecureStorageService();

/**
 * Modules
 */
import { ProccessModule } from "@/store/Modules/proccess-module";
import { AuthModule } from "@/store/Modules/auth-module";
import { ProfileModule } from "@/store/Modules/profile-module";
import { PermissionModule } from "@/store/Modules/permission-module";
import { AdvertiserModule } from "@/store/Modules/advertiser-module";
import { CustomListModule } from "@/store/Modules/custom_list-module";
import { ListItemModule } from "@/store/Modules/list_item-module";
import { AccountModule } from "@/store/Modules/account-module";
import { UserModule } from "@/store/Modules/user-module";
import { CampaignModule } from "@/store/Modules/campaign-module";
import { ModifierModule } from "@/store/Modules/modifier-module";
import { ModelsModule } from "@/store/Modules/models-module";
import { LookalikeModule } from "@/store/Modules/look-a-like-module";
import { ModifierOptionModule } from "@/store/Modules/modifier_options-module";
import { CustomListExchangeModule } from "@/store/Modules/custom_list_exchange-module";
import { LineItemModule } from "@/store/Modules/line_item-module";
import { CreativeModule } from "@/store/Modules/creative-module";
import { TargetingModule } from "@/store/Modules/targeting-module";
import { ListModule } from "@/store/Modules/lists-module";
import { SegmentModule } from "@/store/Modules/segment-module";
import { SegmentCategoryModule } from "@/store/Modules/segment_category-module";
import { SegmentUploadModule } from "@/store/Modules/segment_upload-module";
import { InternalizationModule } from "@/store/Modules/internationalization-module";
import { CustomizeViewModule } from "@/store/Modules/customize_view-module";
import { ReportModule } from "@/store/Modules/report-module";
import { GenerateReportModule } from "@/store/Modules/Reports/V2/generate-report-module";
import { PersonModule } from "@/store/Modules/Persons/v10/person-module";
import { PrivatePoisModule } from "@/store/Modules/PrivatePois/private-pois-module";
import { ColorModule } from "@/store/Modules/color-module";
// V4
// import { AudienceModule } from "@/store/Modules/audience-module";
// V10
import { AudienceModule } from "@/store/Modules/Persons/v10/audience-module";
import { DashboardModule } from "@/store/Modules/dashboard-module";
import { GraphicsAudienceModule } from "@/store/Modules/graphics-audience-module";
import { GraphicsPoisModule } from "@/store/Modules/graphics-pois-module";
import { CustomizerFilterModule } from "@/store/Modules/customize_filter-module";
import { CloneEntityModule } from "@/store/Modules/clone-entity-module";
import { BulkModule } from "@/store/Modules/bulk-module";
import { DownloadModule } from "@/store/Modules/download-module";
import { ActivityLogModule } from "@/store/Modules/activity-log-module";
import { ExportModule } from "@/store/Modules/export-module";
import { RequestCampaignModule } from "@/store/Modules/request-campaign-module";
import { LoadingModule } from "@/store/Modules/loading-module";
import { NotificationModule } from "@/store/Modules/notification-module";
import { BreadCrumbsModule } from "@/store/Modules/BreadCrumbs/breadcrumbs-module";
import { UserSettingModule } from "@/store/Modules/user-setting-module";
import { EventModule } from "@/store/Modules/event-module";
import SecureStorageService from "@/services/secure-storage-service";

/**
 * Version
 */
import { ReportV2Module } from "@/store/Modules/Reports/V2/report-module";

/**
 * Core
 */
import { SnacktifyModule } from "@/store/Modules/Core/snacktify-module";

Vue.use(Vuex);

export default new Vuex.Store({
	state: () => ({}),
	mutations: {},
	actions: {},
	modules: {
		proccess: ProccessModule,
		auth: AuthModule,
		profile: ProfileModule,
		permissions: PermissionModule,
		advertiser: AdvertiserModule,
		custom_list: CustomListModule,
		list_item: ListItemModule,
		account: AccountModule,
		user: UserModule,
		campaign: CampaignModule,
		modifier: ModifierModule,
		modifier_options: ModifierOptionModule,
		customListExchange: CustomListExchangeModule,
		line_item: LineItemModule,
		creative: CreativeModule,
		targeting: TargetingModule,
		lists: ListModule,
		segment: SegmentModule,
		segment_category: SegmentCategoryModule,
		segment_upload: SegmentUploadModule,
		internationalization: InternalizationModule,
		customize_view: CustomizeViewModule,
		report: ReportModule,
		generate_report: GenerateReportModule,
		person: PersonModule,
		dashboard: DashboardModule,
		audience: AudienceModule,
		audience_graphics: GraphicsAudienceModule,
		pois_graphics: GraphicsPoisModule,
		customizer_filter: CustomizerFilterModule,
		clone_entity: CloneEntityModule,
		bulk: BulkModule,
		download: DownloadModule,
		activity_log: ActivityLogModule,
		export: ExportModule,
		request_campaign: RequestCampaignModule,
		loading: LoadingModule,
		notification: NotificationModule,
		privatePois: PrivatePoisModule,
		breadcrumbsM: BreadCrumbsModule,
		user_setting: UserSettingModule,
		events: EventModule,
		colors: ColorModule,
		models: ModelsModule,
		lookalike: LookalikeModule,

		/**
		 * Module version
		 */
		report_v2: ReportV2Module,

		/**
		 * Module Core
		 * Componentes de la App
		 */
		snacktify: SnacktifyModule,
	},
	plugins: [
		/**
		 * modulos para persistir
		 */
		createPersistedState({
			storage: secureStorageService,
			paths: [
				"internationalization",
				"customize_view",
				"breadcrumbsM",
			],
		}),
	],
});
