import {
	AdvertiserDataCreate,
	AdvertiserDataUpdate,
	AdvertiserFilters,
} from "@/interfaces/advertiser";
import { Paginated, SortingOption } from "@/interfaces/paginated";
import { catchServiceErrors, downloadFile, modes, prepareParams } from "@/utils/services-global";
import { isUndefined, isEmpty } from "lodash";
import {
	AxiosGet,
	AxiosPatch,
	AxiosPost,
	GetData,
	GetErrors,
	GetMessage,
} from "./axios-service";

const ROUTE = require("../api/routes").ADVERTISER;

const ENTITY: string = "advertiser"; 
class AdvertiserService {
	async all(
		filters?: AdvertiserFilters,
		options?: SortingOption,
		paginated?: Paginated
	) {
		try {
			let filter = "";

			if (!isUndefined(filters)) {
				filter = getFilters(filters);
			}

			const url = await prepareParams({
				route: ROUTE.ADVERTISER_ROUTE,
				entity: ENTITY,
				paginated: paginated,
				fields: [],
				mode: modes.ALL,
				filter: filter,
				options: options,
			});

			const response = await AxiosGet(url);

			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async paginated(params: {
		paginated?: Paginated;
		filters?: AdvertiserFilters;
		options?: SortingOption;
		fields?: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.ADVERTISER_ROUTE,
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async download(params: {
		paginated?: Paginated;
		filters?: AdvertiserFilters;
		options?: SortingOption;
		fields: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			await downloadFile({
				entity: ENTITY,
				route: ROUTE.ADVERTISER_ROUTE,
				filter,
				mode: modes.DOWNLOAD,
				...params,
			});

			return Promise.resolve({});
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async categories() {
		try {
			const response = await AxiosGet(ROUTE.ADVERTISER_CATEGORIES_ROUTE);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async create(advertiser: AdvertiserDataCreate) {
		try {
			const response = await AxiosPost(
				ROUTE.ADVERTISER_ROUTE,
				advertiser
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async show(id: number) {
		try {
			const response = await AxiosGet(`${ROUTE.ADVERTISER_ROUTE}/${id}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async update(advertiser: AdvertiserDataUpdate, id: number) {
		try {
			const response = await AxiosPatch(
				`${ROUTE.ADVERTISER_ROUTE}/${id}`,
				advertiser
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async list(params: {
		filters?: AdvertiserFilters;
		options?: SortingOption;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.ADVERTISER_ROUTE,
				entity: ENTITY,
				mode: modes.LIST,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async listCreativo(params: {
		filters?: AdvertiserFilters;
		options?: SortingOption;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.ADVERTISER_ROUTE,
				entity: ENTITY,
				mode: modes.ALL,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async searchAdvertiser(params: AdvertiserFilters) {
		try {
			let filter = "";
			if (!isUndefined(params)) {
				filter = getFilters(params);
			}
			const url = `${ROUTE.ADVERTISER_ROUTE}?${filter}`
			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async makers(params: {filters?: AdvertiserFilters}) {
		try {

			let parameters = "";

			if (!isUndefined(params.filters)) {
				parameters = getParams(params.filters);
			}

			const url = isEmpty(parameters) 
						? `${ROUTE.ADVERTISER_MAKERS_ROUTE}` 
						: `${ROUTE.ADVERTISER_MAKERS_ROUTE}?${parameters}` 

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}
	async tradeMarks(params: {filters?: AdvertiserFilters}) {
		try {

			let parameters = "";

			if (!isUndefined(params.filters)) {
				parameters = getParams(params.filters);
			}

			const baseurl = "/api/list/advertiser_trade_marks";
			const url = isEmpty(parameters) ? baseurl : `${baseurl}?${parameters}`;
			
			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}


	
}

function getParams(filters: AdvertiserFilters): string{
	let params = "";
	const country = !!filters.country_id ? filters.country_id : 0;
	const maker = !!filters.maker_id ? filters.maker_id : 0;
	
	var symbol = "";

	if (maker) {
        return params += `${symbol}maker_id=${filters.maker_id}`;
    }

	if (country) {
		params += `${symbol}country_id=${country}`;
	}

	return params;
}

function getFilters(filters: AdvertiserFilters): string {
	let filter = "";
	const id = !!filters.id ? filters.id : "";
	const external_id = !!filters.external_id ? filters.external_id : "";
	const name = !!filters.name ? filters.name : "";
	const category_id = !!filters.category_id ? filters.category_id : "";
	const domain = !!filters.domain ? filters.domain : "";
	const app_bundle = !!filters.app_bundle ? filters.app_bundle : "";
	const active =
		typeof filters.active === "undefined" ? "" : !!filters.active ? 1 : 0;
	

	var symbol = "";

	if (id) {
		filter += `${symbol}filters[advertisers.id]=${id}`;
		symbol = "&";
	}

	if (external_id) {
		filter += `${symbol}filters[advertisers.external_id]=${external_id}`;
		symbol = "&";
	}

	if (name) {
		filter += `${symbol}filters[advertisers.name]=${name}`;
		symbol = "&";
	}

	if (category_id) {
		filter += `${symbol}filters[categories.name]=${category_id}`;
		symbol = "&";
	}

	if (domain) {
		filter += `${symbol}filters[advertisers.domain]=${domain}`;
		symbol = "&";
	}

	if (active) {
		filter += `${symbol}filters[advertisers.active]=${active}`;
		symbol = "&";
	}

	if (active) {
		filter += `${symbol}filters[advertisers.active]=${active}`;
		symbol = "&";
	}

	return filter;
}

export default new AdvertiserService();
