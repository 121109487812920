import {
	Campaign,
	CampaignDataCreate,
	CampaingFilters,
} from "@/interfaces/campaign";
import { Paginated, SortingOption } from "@/interfaces/paginated";
import { V10 } from "@/interfaces/persons/v10/route";
import {
	AxiosPost,
	AxiosGet,
	AxiosPut,
	AxiosPatch,
	GetData,
	GetErrors,
	GetMessage,
	AxiosDelete
} from "@/services/axios-service";
import { prepareParams, modes, downloadFile, catchServiceErrors } from "@/utils/services-global";
import { isNull, isUndefined } from "lodash";

const CON_ROUTE = require("../api/routes").CONTINENT;
const ROUTE = require("../api/routes").CAMPAIGN;
const V10_ROUTE: V10 = require("../api/routes").V10;

const ENTITY: string = "campaign";

class CampaignService {
	async create(campaign: CampaignDataCreate) {
		try {
			const response = await AxiosPost(ROUTE.CAMPAIGN_ROUTE, campaign);
			
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async update(campaign: Campaign, id: Number) {
		try {
			const response = await AxiosPatch(
				`${ROUTE.CAMPAIGN_ROUTE}/${id}`,
				campaign
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async show(id: number) {
		try {
			const response = await AxiosGet(`${ROUTE.CAMPAIGN_ROUTE}/${id}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}
	async getTerms() {
		try {
			const response = await AxiosGet(`${ROUTE.CAMPAIGN_TERMS_ROUTE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async deleteByID(id: number) {
		try {
			const response = await AxiosDelete(`${ROUTE.CAMPAIGN_ROUTE}/${id}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async paginated(params: {
		paginated?: Paginated;
		filters?: CampaingFilters;
		options?: SortingOption;
		fields?: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.CAMPAIGN_ROUTE,
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async download(params: {
		paginated?: Paginated;
		filters?: CampaingFilters;
		options?: SortingOption;
		fields: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			await downloadFile({
				entity: ENTITY,
				route: ROUTE.CAMPAIGN_ROUTE,
				filter,
				mode: modes.DOWNLOAD,
				...params,
			});

			return Promise.resolve({});
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}
	async getContinentList(){
        try {
			const response = await AxiosGet(`${CON_ROUTE.CONTINENT_ROUTE}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
    }
	async getStoreAttribution(){
        try {
			const response = await AxiosGet(`${V10_ROUTE.PERSONS.STORE_ATTRIBUTION}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
    }

	async list(params: { filters?: CampaingFilters; options?: SortingOption }) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.CAMPAIGN_ROUTE,
				entity: ENTITY,
				mode: modes.LIST,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			console.error("list", { error: error });
			return await catchServiceErrors(error);
		}
	}

	async all(params: { filters?: CampaingFilters; options?: SortingOption }) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.CAMPAIGN_ROUTE,
				entity: ENTITY,
				mode: modes.ALL,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			console.error("all", { error: error });
			return await catchServiceErrors(error);
		}
	}

	async createAssociation(entity: any) {
		try {
			const response = await AxiosPost(
				ROUTE.MODIFIER_ASSOCIATION_ROUTE,
				entity
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async createAssociationEvent(entity: {
		campaign_id: number,
		events_ids: number[],
	}) {
		try {
			const response = await AxiosPost(
				ROUTE.CAMPAIGN_ASSOCIATIONS_EVENT_ROUTE,
				entity
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return Promise.reject({
				success: false,
				message: GetMessage(error),
				errors: GetErrors(error),
			});
		}
	}

	async setActive(id: Number, active: Boolean) {
		try {
			const response = await AxiosPut(
				`/api/campaigns/${id}/set/${active ? 1 : 0}`,
				{}
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}
}

/**
 * name,
 * budget,
 * start_date,
 * end_date,
 * spend,
 * advertiser_name,
 * budget_type,
 * currency_name,
 * optimization_strategy_name,
 * strategy_name,
 * campaign_pacing_name,
 * kpi_campaign_name,
 * bid_shading_name,
 * timezone_name
 */
function getFilters(filters: CampaingFilters): string {
	let filter = "";

	const id = !!filters.id ? filters.id : "";
	const external_id = !!filters.external_id ? filters.external_id : "";
	const name = !!filters.name ? filters.name : "";
	const budget = !!filters.budget ? filters.budget : "";
	const start_date = !!filters.start_date ? filters.start_date : "";
	const end_date = !!filters.end_date ? filters.end_date : "";
	const spend = !!filters.spend ? filters.spend : "";
	const advertiser_name = !!filters.advertiser_name
		? filters.advertiser_name
		: "";
	const advertiser_id = !!filters.advertiser_id ? filters.advertiser_id : "";
	const budget_type = !!filters.budget_type ? filters.budget_type : "";
	const currency_name = !!filters.currency_name ? filters.currency_name : "";
	const optimization_strategy_name = !!filters.optimization_strategy_name
		? filters.optimization_strategy_name
		: "";
	const strategy_name = !!filters.strategy_name ? filters.strategy_name : "";
	const campaign_pacing_name = !!filters.campaign_pacing_name
		? filters.campaign_pacing_name
		: "";
	const kpi_campaign_name = !!filters.kpi_campaign_name
		? filters.kpi_campaign_name
		: "";
	const bid_shading_name = !!filters.bid_shading_name
		? filters.bid_shading_name
		: "";
	const timezone_name = !!filters.timezone_name ? filters.timezone_name : "";
	const trafficker_name = !!filters.trafficker_name
		? filters.trafficker_name
		: "";
	const active =
		isUndefined(filters.active) || isNull(filters.active)
			? ""
			: !!filters.active
			? 1
			: 0;
	const alternative_id = !!filters.alternative_id
		? filters.alternative_id
		: "";
	const automatic_allocation = !!filters.automatic_allocation
		? filters.automatic_allocation
		: "";
	const budget_remaining = !!filters.budget_remaining
		? filters.budget_remaining
		: "";
	const line_items_count = !!filters.line_items_count
		? filters.line_items_count
		: "";
	/*
	const impression = !!filters.impression
		? filters.impression
		: "";*/

	var symbol = "";

	if (id) {
		filter += `${symbol}filters[id]=${id}`;
		symbol = "&";
	}

	if (line_items_count) {
		filter += `${symbol}filters[line_items_count]=${line_items_count}`;
		symbol = "&";
	}

	if (external_id) {
		filter += `${symbol}filters[external_id]=${external_id}`;
		symbol = "&";
	}

	if (name) {
		filter += `${symbol}filters[name]=${name}`;
		symbol = "&";
	}

	if (budget) {
		filter += `${symbol}filters[budget]=${budget}`;
		symbol = "&";
	}

	if (start_date) {
		filter += `${symbol}filters[start_date]=${start_date}`;
		symbol = "&";
	}

	if (end_date) {
		filter += `${symbol}filters[end_date]=${end_date}`;
		symbol = "&";
	}

	if (spend) {
		filter += `${symbol}filters[spend]=${spend}`;
		symbol = "&";
	}

	if (advertiser_name) {
		filter += `${symbol}filters[advertiser.name]=${advertiser_name}`;
		symbol = "&";
	}

	if (budget_type) {
		filter += `${symbol}filters[budget_type.description]=${budget_type}`;
		symbol = "&";
	}

	if (currency_name) {
		filter += `${symbol}filters[currency.name]=${currency_name}`;
		symbol = "&";
	}

	if (optimization_strategy_name) {
		filter += `${symbol}filters[optimization_strategy.description]=${optimization_strategy_name}`;
		symbol = "&";
	}

	if (strategy_name) {
		filter += `${symbol}filters[strategy.description]=${strategy_name}`;
		symbol = "&";
	}

	if (campaign_pacing_name) {
		filter += `${symbol}filters[campaign_pacing.description]=${campaign_pacing_name}`;
		symbol = "&";
	}

	if (kpi_campaign_name) {
		filter += `${symbol}filters[kpi_campaign.description]=${kpi_campaign_name}`;
		symbol = "&";
	}

	if (bid_shading_name) {
		filter += `${symbol}filters[bid_shading.description]=${bid_shading_name}`;
		symbol = "&";
	}

	if (timezone_name) {
		filter += `${symbol}filters[timezone.description]=${timezone_name}`;
		symbol = "&";
	}

	if (trafficker_name) {
		filter += `${symbol}filters[trafficker.description]=${trafficker_name}`;
		symbol = "&";
	}

	if (active) {
		filter += `${symbol}filters[active]=${active}`;
		symbol = "&";
	}

	if (budget_remaining) {
		filter += `${symbol}filters[budget_remaining]=${budget_remaining}`;
		symbol = "&";
	}

	if (automatic_allocation) {
		filter += `${symbol}filters[automatic_allocation]=${automatic_allocation}`;
		symbol = "&";
	}

	if (alternative_id) {
		filter += `${symbol}filters[alternative_id]=${alternative_id}`;
		symbol = "&";
	}

	if (advertiser_id) {
		filter += `${symbol}filters[advertiser_id]=${advertiser_id}`;
		symbol = "&";
	}
	/*
	if (impression) {
		filter += `${symbol}filters[campaigns.impression]=${impression}`;
		symbol = "&";
	}*/

	return filter;
}

export default new CampaignService();
